import * as React from 'react';
import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import { NavBar } from '../components/segments/NavBar';
import { Helmet } from 'react-helmet';
import { theme } from '../utils/theme';
import DarkFooter from '../components/segments/DarkFooter';
import { NewAppText, NewAppTitle } from '../components/ui/';
import { mobileSize } from '../utils';

export default () => {
  return (
    <NewIndexLayout>
      <Helmet>
        <meta name="title" content={'Privacy Policy'} />
        <title>{'Rapptr Labs - Privacy Policy'}</title>
        <meta name="description" content={'Privacy Policy'} />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <DocContainer>
        <HeaderText>Privacy Policy</HeaderText>
        <Wrapper>
          <PrivacyPolicyHeading
            style={{ textAlign: 'center', width: '100%', fontSize: '20px', marginBottom: '8px' }}
          >
            PRIVACY STATEMENT
          </PrivacyPolicyHeading>
          <PrivacyPolicyText style={{ textAlign: 'center', width: '100%', marginBottom: '32px' }}>
            Last updated: March 13th, 2022
          </PrivacyPolicyText>
          <ol type="1">
            <li>
              <PrivacyPolicyHeading>
                What does this privacy statement apply to?
              </PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              This Privacy Statement applies to your Personal Information collected or processed by
              D & A Technologies LLC d/b/a Rapptr Labs, and/or its affiliated entities
              (collectively, “<b>Rapptr Labs</b>,” “<b>we</b>,” “<b>us</b>” or “<b>our</b>”),
              through their websites or other sites that display or are linked to this Privacy
              Statement (collectively, the “Site” or “Sites”). This Privacy Statement applies to
              your Personal Information, which can include information or a combination of
              information that could directly or indirectly be used to identify you, in particular
              by reference to an identifier such as a name, an email, login credentials, an online
              identifier, and any other information collected from or about you (hereinafter, “
              <b>Personal Information</b>”). Please note, the definition of Personal Information can
              vary by jurisdiction. If we collect information from you that is considered Personal
              Information in your jurisdiction, we will treat it as such in accordance with this
              Privacy Statement.
            </PrivacyPolicyText>
            <PrivacyPolicyText>
              Our site may contain links to third-party websites and services that are not owned or
              controlled by Rapptr Labs. We are not responsible for the privacy practices or the
              content of third-party websites, services, advertisements, and you visit them at your
              own risk.
            </PrivacyPolicyText>
            <PrivacyPolicyText>
              Please read this Privacy Statement carefully to understand our policies and practices
              regarding your Personal Information and how we will treat it. This Privacy Statement
              may change from time to time. We will notify you of material changes to this Privacy
              Statement by posting a notice on our home page for a reasonable period of time and
              changing the “Last Updated” legend at the top of this page So please check the Site
              periodically for updates
            </PrivacyPolicyText>
            <li>
              <PrivacyPolicyHeading>
                What categories of Personal Information do we collect and process?
              </PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              We may collect or process various categories of Personal Information that you provide
              to us. We may disclose Personal Information to service providers, licensing partners,
              and retailers for business purposes. This may include the following categories of
              Personal Information:
            </PrivacyPolicyText>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  <b>Contact Information Identifiers</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Identifiers sourced directly from you or from third party providers. They are used
                to provide and improve the features, products and services you request; for
                registration and promotions; to communicate with you and provide you with
                information; to provide you with personalized content, information, and to send you
                coupons, offers and other information on our products; when we perform our
                obligations under a contract with you; to deliver relevant advertising; and/or for
                research purposes.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Customer Records</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Personal Information categories contained in customer records are sourced directly
                from you. They are used to provide and improve the features, products and services
                you request; to provide you with personalized content, information, and to send you
                coupons, offers, and other information on our products or affiliates; and when we
                perform our obligations under a contract with you.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Internet Activity</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Internet or other similar network activity is sourced indirectly from you (e.g.,
                from observing your actions on our Sites) or from third party service providers,
                such as a data analytics provider. They are used to provide and improve the
                features, products and services you request; to provide you with personalized
                content or information, and to send you coupons, offers, and other information on
                our products or affiliates; and/or to deliver relevant advertising.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Third Party Sources</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may collect information, including contact lists, from third parties and other
                sources. This data may be combined with other information that we collect and use
                for the purposes described in this Privacy Statement. Likewise, certain third party
                service providers on our Sites may collect Personal Information about you when they
                are performing services on our behalf and/or when you are using certain functions on
                our Sites that are operated by these third parties. Any information, including
                Personal Information, collected by such third party service providers will be
                subject to those third parties’ privacy notices or privacy policies, rather than
                this Privacy Statement.
              </PrivacyPolicyText>
            </ol>
            <li>
              <PrivacyPolicyHeading>
                WHAT specific pieces of Personal Information May BE COLLECTED?
              </PrivacyPolicyHeading>
            </li>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  Personal contact details including information such as names, addresses, telephone
                  numbers, e-mail addresses, or login credentials, such as when you provide them to
                  us during online registration or when doing business with us. Some Sites may allow
                  you to establish a user name and password to set up an account.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Information collected from our Sites and social media pages. This includes
                  information collected when you interact with us, such as comments, photos, or
                  other information that you post through blogs, message boards, or social
                  networking opportunities or other interactive forums, as well as information that
                  you may provide when making an inquiry about our products and services, such as
                  estimated budget ranges.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Your Internet Protocol (IP) address or device identifier, your device’s operating
                  system, browser type, websites visited before or after you visit our Site, pages
                  viewed and activities at our Site, as well as your interaction with
                  advertisements.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Third-party service and advertising partners may also collect information
                  automatically.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Other information we have collected from you with your consent.
                </PrivacyPolicyText>
              </li>
            </ol>
            <li>
              <PrivacyPolicyHeading>
                Why do we collect and how do we use your Personal Information?
              </PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              We may use your Personal Information for a variety of legitimate purposes including:
            </PrivacyPolicyText>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  <b>Providing the features, products and services you request</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may use the Personal Information we collect about you to fulfill your requests
                for, and enable your use of, our features, products and services.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Registration, contests and promotions</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may use your Personal Information to enter you in contests if you so choose to
                enter, to notify you of results and to register you for certain features, products
                and services in line with your chosen marketing preferences.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>To communicate with you and provide you with information</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may use your Personal Information to communicate with you, to respond to your
                questions or comments, and to provide you with updates and news.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>To provide you with personalized content, offers, and other information</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may use your Personal Information to provide you personalized offers and content.
                Where required by law, we ask visitors providing e-mail addresses to indicate if
                they would like further information or updates from us, such as information about
                the products and services available from our family of companies. We also offer
                visitors an “opt-out” option if they wish to cease receiving e-mails or other
                information from us. In some circumstance you may be asked to “opt-in” before we
                contact you in certain ways or before certain services may become available.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>When we perform our obligations under a contract with you</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may use your Personal Information to carry out our obligations under any
                agreement that we may have with you.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>To deliver relevant advertising</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may purchase and use opt-in e-mail lists to contact potential customers who we
                believe may be interested in products or services from Rapptr Labs. We will honor
                opt-out requests and ask those providing lists to give us assurances that e-mail
                contact information is provided only for those consumers who have expressed a desire
                to receive offers and information by e-mail. We also may use demographic and
                preference information to allow advertising related to Rapptr Labs products and
                services to be targeted to the users for whom they are most pertinent. This is
                designed so that users will see advertising that is most likely to interest them,
                and advertisers can display their ads to people who are most likely to be receptive.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>
                    To improve the features, products and services that we offer through the Site
                  </b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may use the Personal Information that we collect to improve the Rapptr Labs
                products and services, as well as our Sites and features.
              </PrivacyPolicyText>
            </ol>
            <li>
              <PrivacyPolicyHeading>
                How do we share and disclose your information?
              </PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              We may share or disclose your Personal Information:
            </PrivacyPolicyText>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  With vendors or agents. For example, we may share your Personal Information with
                  companies we have hired to provide services on our behalf. When we share Personal
                  Information with these other companies to provide services for us, they are not
                  allowed to use it for any other purpose and must keep it confidential unless you
                  otherwise consent.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Where your Personal Information may be transferred along with other business
                  assets.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Where we have received your direction or consent to the disclosure of your
                  Personal Information.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  To comply with the law or respond to legal process or lawful requests, including
                  from law enforcement and government agencies.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  To conduct investigations of consumer complaints or possible breaches of law, to
                  protect the integrity of the Site, to fulfill your requests, or to cooperate in
                  any legal investigation.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  To protect the rights or property of the Rapptr Labs or our customers, including
                  enforcing the terms governing your use of the services.
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  To act on a good faith belief that access or disclosure is necessary to protect
                  the safety of our associates, customers or the public.{' '}
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  To support other legitimate business purposes including but not limited to legal
                  compliance.{' '}
                </PrivacyPolicyText>
              </li>
            </ol>
            <PrivacyPolicyText>
              Please note that the Sites may include links or otherwise refer to third-party
              websites whose privacy practices may differ from our privacy practices. If you submit
              Personal Information to any of those websites, your information is governed by the
              privacy policies on those websites. We encourage you to review the privacy policy of
              any website you visit.
            </PrivacyPolicyText>
            <PrivacyPolicyText>
              We may share anonymized or de-identified information we collect under any of the above
              circumstances. We may also share it with third parties to develop and deliver targeted
              advertising on the Sites, and on websites of third parties. We may combine anonymized
              or de-identified information we collect with additional anonymized or de-identified
              information collected from other sources. We also may share aggregated and anonymized
              information with third parties, including advisors, advertisers, and investors, for
              the purpose of conducting general business analysis. For example, we may tell our
              advertisers the number of visitors to our Site and the most popular features or
              services accessed.
            </PrivacyPolicyText>
            <li>
              <PrivacyPolicyHeading>What about children’s privacy?</PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              Our site is not intended for children under the age of 18 and do not knowingly collect
              Personal Information for anyone under the age of 18. We may ask visitors to enter a
              birth date to restrict those who are underage from accessing certain services,
              websites, areas or features. If you become aware that a child under 13 has registered
              for our email newsletters, or otherwise provided us with their Personal Information,
              please report the use to <a href="mailto:info@rapptrlabs.com">Info@RapptrLabs.com</a>.
            </PrivacyPolicyText>
            <li>
              <PrivacyPolicyHeading>
                What are our policies regarding online interest-based advertising?
              </PrivacyPolicyHeading>
            </li>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  <b>How do we serve advertisements through online interest-based advertising?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                At times we may display ads on the websites of third parties. When you view ads that
                we serve elsewhere online, you may see different kinds of ads, such as text ads next
                to search results or video ads on web pages. Sometimes, these ads may be based on
                the content of the pages on which they appear. Other times, these ads may be
                generated by matching the particular ad to your interests as inferred from your
                online activities that have been collected over time. With interest-based
                advertising, the goal is to show you ads that are most relevant to you.
              </PrivacyPolicyText>
              <PrivacyPolicyText>
                To do this, we and some of our third-party advertising companies may use
                technologies such as cookies and web beacons to collect information about your
                interaction with our Sites and other third-party websites (“Online Data”). This
                Online Data generally does not identify you to us personally and, typically, it is
                aggregated with other data to create segments – groups of users and certain general
                interest categories that we have inferred based on a variety of factors (for
                example, “fashion fan”). The Online Data can include:
              </PrivacyPolicyText>
              <ul>
                <li>
                  <PrivacyPolicyText>
                    The webpages, products and services you view and links you click when using our
                    Sites and services as well as third-party websites;
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    Our e-mails that you view and links you click on within that e-mail;
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    Whether you view or click on ads that are shown to you;
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    Demographic or interest data, including general geographic location derived from
                    IP address;
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    The search terms you enter when using certain search services.
                  </PrivacyPolicyText>
                </li>
              </ul>
              <PrivacyPolicyText>
                We and our third-party advertising partners may use this Online Data to paint a more
                accurate picture of the interests of the audiences with which we interact –
                including you – so that we can serve ads that are more relevant to those interests.
              </PrivacyPolicyText>
              <PrivacyPolicyText>
                In addition, our third-party advertising partners may use this Online Data for a
                variety of other purposes, including (a) in conjunction with advertising that
                appears on our Site or other websites, (b) for reporting Site traffic, statistics,
                advertisement data and other interaction with ads and the Sites on which they are
                served, and (c) to measure the effectiveness of web-based and e-mail advertisements.{' '}
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Why would you want to receive interest-based ads?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Consumers like to receive interest-based ads for many different reasons.
                Interest-based ads are helpful because they are more tailored to your particular
                interests. For example, cat owners may see more ads for cat food and fewer ads for
                dog grooming services. Interest-based ads also are more likely to help you discover
                new products and services that are actually relevant to you and your interests. For
                example, individuals interested in fashion may see an ad about the latest fashion
                forward products, whereas general retail customers might be more likely to see an ad
                for special discounted clothing lines. In short, receiving interest-based ads can
                help to improve your access to new products, services and features that are most
                relevant to you. You also will not see the same ads over and over from us because
                the number of times you see a particular interest-based ad is limited. By opting-out
                of interest-based ads, you lose all of these benefits.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>How can you opt-out of receiving interest-based ads from us?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                If you are no longer interested in receiving interest-based ads from any of our
                Sites, you can opt-out by contacting us at{' '}
                <a href="mailto:info@rapptrlabs.com">Info@RapptrLabs.com</a>. Please note, however,
                that opting-out may not mean you will no longer see ads from us online. It simply
                means that the online ads from us you do see will not be based on your particular
                interests.
              </PrivacyPolicyText>
            </ol>
            <li>
              <PrivacyPolicyHeading>Cookies Notice</PrivacyPolicyHeading>
            </li>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  <b>What are cookies and how do we use them?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                “Cookies” are data files that a website sends to your computer while you are viewing
                the site. These data files include information that allows our Sites to remember
                important information that will make your use of the Sites more efficient and useful
                to you. Our Sites may use cookies for a variety of purposes. We may use cookie
                technology and IP addresses to obtain information from online visitors, and also to
                provide registered visitors with the best possible personalized online experience.
                Information obtained via cookies may include Personal Information.
              </PrivacyPolicyText>
              <PrivacyPolicyText>
                Visitors to our Sites use different web browsers and different computers. To make
                your visits as easy as possible with the technology you use, we may automatically
                keep track of the type of browser (e.g., Internet Explorer) and operating system
                (e.g., Windows, Mac) used by a visitor, and the domain name of the visitor's
                Internet service provider. We may also track the total number of visitors to our
                Sites in an aggregate form to allow us to update and improve our Sites. This data
                tells us if more visitors prefer certain features or areas to others, which helps us
                keep our Sites fresh and interesting to the majority of our visitors.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>How do we use cookies to personalize your experience at our sites?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Cookie technology helps us deliver content tailored to a visitor’s interests. Where
                appropriate under our disclosed practices and policies, we may associate Personal
                Information with a cookie file in such instances.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Do third parties use cookies on our site?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We may allow third parties to use cookies and to advertise on our Sites. In general,
                we may not control the cookies used by third parties. There are free programs you
                can download which would allow you to set your preferences and block the vast
                majority of third-party cookies across the Internet.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>Different types of cookies used by us</b>
                </PrivacyPolicyText>
              </li>
              <ul>
                <li>
                  <PrivacyPolicyText>
                    Required cookies enable you to navigate the Site and use essential features.
                    These cookies do not gather any information about you that could be used for
                    marketing or remembering where you've been on the Internet. Each time you access
                    our Site, a cookie containing a unique identifier that is tied to your account
                    is placed on your browser.
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    Performance cookies collect information about how you use our Site, e.g., which
                    pages you visit, and if you experience any errors. These cookies don't collect
                    any information that could identify you – all the information collected is
                    anonymous and is only used to help us improve how our Site works, understand
                    what interests our users, and measure how effective our advertising is.
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    Functionality cookies may be used to provide services or to remember settings to
                    improve your visit and provide enhanced more personal features
                  </PrivacyPolicyText>
                </li>
                <li>
                  <PrivacyPolicyText>
                    Targeting or advertising cookies may linked to services provided by third
                    parties, such as 'Like' buttons and 'Share' buttons. The third party provides
                    these services in return for recognizing that you have visited our Site.
                  </PrivacyPolicyText>
                </li>
              </ul>
              <li>
                <PrivacyPolicyText>
                  <b>What are web beacons and how do we use them?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Some of our Site pages and e-mail updates may contain electronic images called Web
                beacons, sometimes known as one pixel GIFs, clear GIFs or pixel tags. On Sites,
                these allow us to count visitors who have viewed our pages. In promotional email
                messages/newsletters, they allow us to count how many subscribers have read them.
                Web beacons allow us to develop statistical information about the activities and
                features that most interest our consumers for the purpose of providing more
                personalized content. They are not used to access your Personal Information without
                your consent.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>How can you opt-out of cookies?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                If you don't want cookies, you can set your computer to warn you each time a cookie
                is being sent or turn off all cookies through your browser (e.g., Internet Explorer
                or Firefox). Check your browser HELP menu to learn the correct way to change or
                update your cookies. Alternatively, you may wish to visit other sources which
                contain comprehensive information on how to do this on a wide variety of browsers.
                You can also look up details on how to delete cookies from your computer as well as
                more general information about cookies. For information on how to do this on the
                browser of your mobile phone you will need to refer to your handset manual. Please
                be aware that restricting cookies may impact the functionality of Rapptr Labs Sites.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>How We Respond to Do-Not-Track Disclosures</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We do not support “Do Not Track” browser settings and do not currently participate
                in any Do Not Track frameworks that would allow us to respond to signals or other
                mechanisms from you regarding the collection of your Personal Information. To learn
                more about how DNT works, please visit{' '}
                <a href="http://allaboutdnt.com/" target="_blank">
                  http://allaboutdnt.com/
                </a>
                .
              </PrivacyPolicyText>
            </ol>
            <li>
              <PrivacyPolicyHeading>What else do you need to know?</PrivacyPolicyHeading>
            </li>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  <b>Where is your information stored and processed?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                Personal Information collected on our Site, or received by Rapptr Labs from you or
                third parties, may be stored and processed in the United States or any other country
                where we or our service providers maintain facilities.
              </PrivacyPolicyText>
              <PrivacyPolicyText>
                The servers and databases in which Personal Information may be stored may be located
                outside the country from which you accessed the Site and in a country that does not
                have the same privacy laws as your country of residence. The Personal Information
                you provide us may be transmitted abroad in accordance with legal requirements, but
                we will collect, process and use personal information only in accordance with this
                Privacy Statement.
              </PrivacyPolicyText>
              <PrivacyPolicyText>
                By visiting the Site and submitting information, you consent to such collection,
                processing, transfer and/or storage of any Personal Information that you submit.
                Please note: our policies may differ by region, so if you visit one of our Site in a
                different region, please check to see if there is a Statement specific to that
                location.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>How long do we store your Personal Information?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We retain and store your Personal Information only for as long as we have a
                legitimate business purpose to do so and in accordance with our data retention
                policies.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>
                    What about Personal Information that is publicly available (such as in chat
                    rooms, message boards, or other interactive forums)?
                  </b>
                </PrivacyPolicyText>
              </li>

              <PrivacyPolicyText>
                We may offer chat rooms, message or bulletin boards, or interactive areas where
                visitors may post comments or information for our visitors’ enjoyment. If there is a
                chat room, bulletin or message board, social networking opportunities or other
                interactive areas where Personal Information may be posted at this Site, be sure to
                check posted rules before entering, as you will be bound by them as well as our
                Terms of Service. Anything you post online is public information, and we are not
                responsible for anything you voluntarily post online. Users should exercise caution
                when disclosing Personal Information online.
              </PrivacyPolicyText>

              <li>
                <PrivacyPolicyText>
                  <b>Assignment</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                In the event that all or part of our assets are sold or acquired by another party,
                or in the event of a merger, you grant us the right to assign the Personal
                Information we have collected from you.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>How do we safeguard your Personal Information?</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                We have implemented administrative, physical and technical safeguards designed to
                protect your Personal Information. No security measures can be 100% secure, however,
                and so we recommend that you take additional measures to protect yourself and your
                Personal Information, including by installing up to date anti-virus software,
                closing browsers after use, keeping confidential your log-in credentials and
                passwords, as applicable, and making sure that you regularly update software and
                apps you have downloaded to ensure you have enabled the latest security features on
                your devices.
              </PrivacyPolicyText>
              <li>
                <PrivacyPolicyText>
                  <b>International Transfer of Personal Information</b>
                </PrivacyPolicyText>
              </li>
              <PrivacyPolicyText>
                This Site is hosted in the United States. Please note that our Sites are directed
                towards users who reside in the United States. By using our Sites, you consent to
                the collection, storage, processing, and transfer of Personal Information in and to
                the United States, or other countries and territories, pursuant to the laws of the
                United States. While some of these countries may not offer the same level of privacy
                protection as your own, we commit to uphold the privacy protections as explained in
                this Privacy Statement. If you are visiting this Site from outside of the United
                States, please note that by providing your Personal Information it is being
                transferred to, stored, collected, or processed in the United States, where our data
                center and servers are located and operated.
              </PrivacyPolicyText>
            </ol>
            <li>
              <PrivacyPolicyHeading>What are your California Privacy Rights?</PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              California Civil Code Section 1798.83 permits users who are California residents to
              request certain information regarding our disclosure of Personal Information to third
              parties for their direct marketing purposes. To make such a request, please contact us
              using the contact information provided below and put “Data Subject Request” in the
              subject line of your request.
            </PrivacyPolicyText>
            <PrivacyPolicyText>
              California law affords residents of California certain additional rights:
            </PrivacyPolicyText>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <PrivacyPolicyText>
                  The right to know, through a general privacy policy and with more specifics
                  available upon request, what Personal Information a business has collected about
                  them in the preceding 12 months, what it is being used for, whether it is being
                  disclosed or sold, and to whom it is being disclosed or sold;
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  The right to “opt out” of processing or allowing a business to sell their Personal
                  Information to third parties (or, for consumers who are under 16 years old, the
                  right not to have their Personal Information sold absent their, or their parent’s,
                  opt-in);
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  The right to have a business delete their Personal Information, with certain
                  exceptions as provided by law; and
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  The right to non-discrimination to receive equal service and pricing from a
                  business, even after exercising your rights.
                </PrivacyPolicyText>
              </li>
            </ol>
            <PrivacyPolicyText>
              California consumers can make a request related to the above noted rights by emailing{' '}
              <a href="mailto:info@rapptrlabs.com">Info@RapptrLabs.com</a>.
            </PrivacyPolicyText>
            <PrivacyPolicyText>
              While we take measures to ensure that those responsible for receiving and responding
              to your request are informed of your rights and how to help you exercise those rights,
              when contacting us to exercise your rights, we ask you to please adhere to the
              following guidelines:
            </PrivacyPolicyText>
            <ul>
              <li>
                <PrivacyPolicyText>
                  Tell Us Which Right You Are Exercising: Specify which right you want to exercise
                  and the Personal Information to which your request relates (if not to you). If you
                  are acting on behalf of another consumer, please clearly indicate this fact and
                  your authority to act on such consumer’s behalf;
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Help Us Verify Your Identity: Provide us with information to verify your identify.
                  For example, provide us (at a minimum) your phone number or email address.  If
                  your phone number or email address appears in our records, we may contact you to
                  help confirm that you are making the request.  Please note that if we cannot
                  initially verify your identity, we may request additional information to complete
                  the verification process. Any Personal Information you disclose to us for purposes
                  of verifying your identity will solely be used for the purpose of verification. 
                </PrivacyPolicyText>
              </li>
              <li>
                <PrivacyPolicyText>
                  Direct Our Response Delivery: Please provide us with an e-mail or mailing address
                  through which we can provide our response.  If make the request by email, unless
                  otherwise requested, we will assume that we can respond to the email address from
                  which you made the request.
                </PrivacyPolicyText>
              </li>
            </ul>
            <PrivacyPolicyText>
              The CCPA gives California Consumers the right to lodge a complaint with the California
              Attorney General’s office. The Attorney General’s office may be contacted at{' '}
              <a
                href="https://oag.ca.gov/contact/consumer-complaint-against-business-or-company"
                target="_blank"
              >
                https://oag.ca.gov/contact/consumer-complaint-against-business-or-company
              </a>{' '}
              or by telephone at: <a href="tel:9162106276">(916) 210-6276</a>.
            </PrivacyPolicyText>
            <li>
              <PrivacyPolicyHeading>
                What should you do if you have questions or want to contact us?
              </PrivacyPolicyHeading>
            </li>
            <PrivacyPolicyText>
              We take steps to keep your Personal Information accurate. Some Rapptr Labs programs
              may provide access to view or update Personal Information online. Check where you
              registered to learn if you can view or update your Personal Information there.
            </PrivacyPolicyText>

            <PrivacyPolicyText>
              Where our processing of your Personal Information is based on your consent, you may
              have the right to withdraw your consent by contacting us. Note that your withdrawal of
              consent will not affect the lawfulness of our processing of your Personal Information
              based on your consent prior to your withdrawal. We are intent on resolving any issues
              relating to our handling of your Personal Information, and you may contact us at any
              time regarding any questions or concerns. We are committed to working with you to
              resolve any complaints you may have regarding this Privacy Statement or the treatment
              of your Personal Information. If you have a concern, please contact us and your
              communication will be promptly addressed. You may contact us by emailing{' '}
              <a href="mailto:info@rapptrlabs.com">Info@RapptrLabs.com</a>.
            </PrivacyPolicyText>
          </ol>
        </Wrapper>
      </DocContainer>
      <DarkFooter />
    </NewIndexLayout>
  );
};

const HeaderText = styled(NewAppTitle)`
  width: 100%;
  text-align: center;
  background-color: ${theme.BACKGROUND_COLOR};
  color: ${theme.WHITE_COLOR};
  font-weight: 700;
  font-size: 62px;
  padding-top: 77.5px;
  padding-bottom: 77.5px;
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-top: 50px;
  padding-bottom: 150px;
  @media ${mobileSize} {
    padding: 30px 2rem;
  }
`;

const PrivacyPolicyText = styled(NewAppText)`
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const PrivacyPolicyHeading = styled(NewAppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: bold;
  text-transform: uppercase;
`;

const DocContainer = styled.div`
  background: ${theme.WHITE_COLOR};
  /* max-width: 900px; */
  width: 100%;
  padding: 30px auto;
  padding-top: 120px;
`;

const NewIndexLayout = styled(IndexLayout)``;
